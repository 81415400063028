import React, { useEffect } from 'react'
import type { GetServerSideProps, NextPage } from 'next'
import Head from 'next/head'
import Cookies from 'universal-cookie'

import Environment from '@/core/classes/environment'
import { NodeEnv } from '@/constants/enums'
import { AppContextProvider, AppContextProps } from '@/core/contexts/app'
import render from '@/core/render'
import configService from '@/services/http/wap/config'
import siteService from '@/services/http/wap/site'
import pageService, { Page } from '@/services/http/wap/page'
import Error, { ErrorProps } from '@/components/error'
import CookieManager from '@/components/widgets/cookie-manager'
import SeoMeta from '@/components/base/seo-meta'
import parse from 'html-react-parser'

type PageProps = AppContextProps & {
  error?: ErrorProps
}

const Page: NextPage<PageProps> = ({ error, ...context }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log(context)
    }

    if (context.language) {
      const cookie = new Cookies()

      cookie.set('language', context.language)
    }
  }, [context])

  const renderColorPalette = () => {
    const { colors, neutrals } = context.theme

    const getcss = (name: string, tones: Record<string, string>) => {
      const entries = Object.entries(tones)

      return entries
        .map(([key, value]) => {
          if (name.length == 2) {
            return `--${name}${key}: ${value};
                    --${name}${key}-RGB: ${value.replace(/[rgb|\(\)]/gm, '')};`
          }

          return `--${name}${key}: ${value};`
        })
        .join('')
    }

    return (
      <style type="text/css">{`:root {${[
        getcss('DN', neutrals.dark),
        getcss('LN', neutrals.light),
        getcss('PC', colors.primary),
        getcss('R', colors.red),
        getcss('Y', colors.yellow),
        getcss('G', colors.green),
        getcss('B', colors.blue),
        getcss('P', colors.purple),
        getcss('T', colors.teal),
      ].join('')}}`}</style>
    )
  }

  const renderStyle = () => {
    const { styles } = context.settings

    if (styles) {
      return <style type="text/css">{styles}</style>
    }
  }

  const renderAlternates = () => {
    const { alternates } = context.page

    if (alternates) {
      return alternates.map(({ title, href, hreflang }, index) => (
        <link
          key={index}
          rel="alternate"
          title={title}
          hrefLang={hreflang}
          href={href}
        />
      ))
    }
  }

  const renderPlugins = () => {
    const { plugins } = context.settings
    return plugins.map((item) => parse(item))
  }

  if (error) {
    return <Error {...error} />
  }

  return (
    <React.StrictMode>
      <Head>
        <title>{context.page.title}</title>
        {renderAlternates()}
        {renderColorPalette()}
        {renderPlugins()}
        <script
          async
          src="https://www.googletagmanager.com/gtag/...Z4833DT2S5"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-Z4833DT2S5');
        `}
        </script>
      </Head>
      <SeoMeta context={context} />
      <AppContextProvider value={context}>
        {context.page.layout.map((component) => render(component, context))}
        <CookieManager />
      </AppContextProvider>
      {renderStyle()}
    </React.StrictMode>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const cookieLanguage = context.req.cookies?.language
  const props: Partial<PageProps> = {
    container: 'browser',
    headers: context.req.headers,
    cookies: context.req.cookies,
    params: context.query,
    language: cookieLanguage,
  }

  if (context.req.headers.host) {
    const environment = new Environment()
    const host = context.req.headers.host.replace('www.', '')
    const params = context.query.pathname || []
    let pathname = typeof params === 'string' ? params : params.join('/')
    let parts = pathname.split('/')

    if (process.env.NEXT_PUBLIC_ENV === NodeEnv.Production) {
      try {
        const site = await siteService.get(environment.context, host)

        environment.update({
          cdn: 'https://minio.asggrup.com',
          environment: process.env.NEXT_PUBLIC_ENV,
          key: site.key,
          service: `${site.service}/${site.key}`,
          url: site.url,
        })

        if (parts.length) {
          props.language = parts[0] || cookieLanguage || 'en'
        }
      } catch (error: any) {
        return { props: { error } }
      }
    } else {
      if (parts.length) {
        environment.set('key', parts[0])

        if (parts[1]) {
          props.language = parts[1]

          pathname = parts.slice(1, parts.length).join('/')
        } else {
          pathname = ''
        }
      }
    }

    try {
      const config = await configService.get(environment.context, {
        language: String(props.language),
      })

      props.settings = config.settings
      props.themes = config.themes
      props.languages = config.languages
      props.language = config.language
      props.environment = environment.context
    } catch (error: any) {
      props.error = error
    }

    if (props.settings && props.themes) {
      try {
        let page: Page | null = null
        const previewId = context.query['previewId']

        if (previewId) {
          page = await pageService.preview(
            environment.context,
            String(previewId)
          )
        } else {
          page = await pageService.get(environment.context, {
            path: pathname || props.settings.homepage,
          })
        }

        if (page) {
          props.page = page
        }
        props.theme = props.themes.find((theme) => {
          if (page && page.theme) {
            return theme.id === page.theme
          } else {
            return theme.isDefault === true
          }
        })
      } catch (error: any) {
        props.error = error
      }
    }
  }

  return { props }
}

export default Page
